import * as React from 'react';
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Switch, TextField,Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {getString} from '../Utility/getSystemString';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {getDefaultDateRange, getFormatedDate} from '../Utility/dateUtils';
import {fetchCodeDescriptionMap, getCodeDescription, fetchUserNames, fetchTenantNames} from '../Utility/getCodeDescription';
import { isValidEmail, isValidPhone, isRequiredFiledIsEmpty } from "../Utility/validations";
import MessageSection from '../common/message';
import {getTenantIdFromToken} from '../Utility/tokenParsar';


const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const { formattedToDate, formattedFromDate } = getDefaultDateRange();
const SharingGroupParticipantsListPage = () => {

  const navigate = useNavigate();
  const [data, setData] = useState([]);  
  const [loading, setLoading] = useState(true);  
  const [error, setError] = useState(null);  
  const [info, setInfo] = useState(null);  
  const [success, setSuccess] = useState(null);  
  const [modelError, setModelError] = useState(null);
  const [tenants, setTenants] = useState([]);

  const [statuses, setStatus] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [userNames, setUserNames] = useState(null);
  const [selectedParticipant, setSelectedParticipants] = useState(null); 

  const [isViewEditModalOpen, setIsViewEditModalOpen] = useState(false); 
  const [modeTitle, setModelTitle] = useState('');
  const [modelname, setModelName] = useState('');
  const [modelEmai, setModelEmail] = useState(''); 
  const [modelStatus, setModelStatus] = useState('');
  const [modelUserId, setModelUserId] = useState('');
  const [modelCountryCode, setModelCountryCode] = useState('');
  const [modelPhone, setModelPhone] = useState('');
  const [modelVerified, setModelVerified] = useState('');
  const [modelComment, setModelComment] = useState('');
  const [modelCreated, setModelCreated] = useState(''); 
  const [modelLastUpdate, setModelLastUpdate] = useState(''); 
  const [modelTenant, setModelTenant] = useState(''); 
  const [isReadOnly, setIsReadOnly] = useState(false); 
  const [isNewRecord, setIsNewRecord] = useState(false);

  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
  const [nameError, setNameError] = React.useState(false);
  const [nameErrorMessage, setNameErrorMessage] = React.useState('');
  const [phoneError, setPhoneError] = React.useState(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = React.useState('');

  const [filters, setFilters] = useState({
    status: 'ACTV',
    from_date: formattedFromDate,
    to_date: formattedToDate
  });

  useEffect(() => {
    // Function to fetch data from the backend
    const fetchData = async () => {
      try {
        setModelError(false);
        setError(false);
        setInfo(false);
        setSuccess(false);
        const token = localStorage.getItem("token");
        const currentPage = window.location.pathname; // Get the current page or route
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Page-Name': currentPage,
          },
        };
        const apiUrl = `api/participants/read?from_date=${filters.from_date}&to_date=${filters.to_date}&status=${filters.status}`;
        const response = await axios.get(apiUrl, config);
        setData(response.data.participants);

        const statusesData = await fetchCodeDescriptionMap('statuses');
        setStatus(statusesData);

        const countryCodeData = await fetchCodeDescriptionMap('country_codes');
        setCountryCodes(countryCodeData);

        const userNameData = await fetchUserNames();
        setUserNames(userNameData);

        const tenantData = await fetchTenantNames();
        setTenants(tenantData);

      } catch (error) {
          if (error.response && error.response.data && typeof error.response.data.error === 'string')
            setError(error.response.data.error );
          if(error.status === 401)
            navigate('/logout');
        } finally {
          setLoading(false);  // Stop the loading spinner
        }
    };

    fetchData();
  }, [filters, navigate]);

  // Handle click for "View" (read-only mode)
  const handleViewClick = (participant) => {
    setSelectedParticipants(participant); 
    setModelName(participant.name); 
    setModelEmail(participant.email); 
    setModelStatus(participant.hStatus);
    setModelCountryCode(participant.hCountryCode);
    setModelPhone(participant.phone);
    setModelVerified(participant.hVerified);
    setModelComment(participant.comment);
    setModelCreated(participant.create_date);
    setModelTenant(participant.htenant_id);
    setModelLastUpdate(participant.last_update_date);
    setIsReadOnly(true);  // Open modal in read-only mode
    setIsViewEditModalOpen(true);  // Open the modal
    setModelTitle(getString('VIEW'));
    setNameError(false);
    setEmailError(false);
    setPhoneError(false);
    setPhoneErrorMessage('');
    setEmailErrorMessage('');
    setNameErrorMessage('');
    setModelError(false);
    setError(false);
    setInfo(false);
    setSuccess(false);
  };

   // Function to handle Edit button click
   const handleEditClick = (participant) => {
    
    setSelectedParticipants(participant); 
    setModelName(participant.name); 
    setModelEmail(participant.email); 
    setModelStatus(participant.hStatus);
    setModelUserId(participant.hUser_id);
    setModelCountryCode(participant.hCountryCode);
    setModelPhone(participant.phone);
    setModelVerified(participant.hVerified);
    setModelComment(participant.comment);
    setModelCreated(participant.create_date);
    setModelTenant(participant.htenant_id);
    setModelLastUpdate(participant.last_update_date);
    setIsReadOnly(false);  // Open modal in read-only mode
    setIsViewEditModalOpen(true);  // Open the modal
    setModelTitle(getString('EDIT'));
    setNameError(false);
    setEmailError(false);
    setPhoneError(false);
    setPhoneErrorMessage('');
    setEmailErrorMessage('');
    setNameErrorMessage('');
    setModelError(false);
    setError(false);
    setInfo(false);
    setSuccess(false);
  };

  // Function to handle add button click
  const handleAddClick = () => {
    setSelectedParticipants(null);
    setModelName(''); // Pre-fill username in the form
    setModelEmail(''); 
    setModelStatus('ACTV');
    setModelTenant(getTenantIdFromToken);
    setModelCountryCode('');
    setModelPhone('');
    setModelComment('');
    setIsReadOnly(false);
    setIsViewEditModalOpen(true);
    setIsNewRecord(true); 
    setModelTitle(getString('CRET'));
    setNameError(false);
    setEmailError(false);
    setPhoneError(false);
    setPhoneErrorMessage('');
    setEmailErrorMessage('');
    setNameErrorMessage('');
    setModelError(false);
    setError(false);
    setInfo(false);
    setSuccess(false);
  };

  // Function to handle delete button click
  const handleDeleteClick =  async (participant) => {
    setModelError(false);
    setError(false);
    setInfo(false);
    setSuccess(false);
    setSelectedParticipants(participant); // Set the selected user for editing
    try {
          const token = localStorage.getItem("token");
          const currentPage = window.location.pathname;
          const config = {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
              'Page-Name': currentPage,
            },
          };
          const delRes = await axios.delete(`api/participants/delete/participants_id=${participant.id}`, config);
          const apiUrl = `api/participants/read`;
          const response = await axios.get(apiUrl, config);
          setData(response.data.participants);
          if(delRes.status === 200)
            setSuccess(delRes.data.msg);
    } catch (error) {
        if (error.response && error.response.data && typeof error.response.data.error === 'string') {
          setError(error.response.data.error );
        }
    }  
  };

  const validateInputs = () => {
    let isValid = true;
    const name = document.getElementById('name');
    const email = document.getElementById('email');
    const phone = document.getElementById('phone');

    if (!isValidEmail(email)) {
      setEmailError(true);
      setEmailErrorMessage(getString('VMEM'));
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!isValidPhone(phone)) {
      setPhoneError(true);
      setPhoneErrorMessage(getString('VMPH'));
      isValid = false;
    } else {
      setPhoneError(false);
      setPhoneErrorMessage('');
    }

    if (!isRequiredFiledIsEmpty(name)) {
      setNameError(true);
      setNameErrorMessage(getString('EMFV'));
      isValid = false;
    } else {
      setNameError(false);
      setNameErrorMessage('');
    }

    return isValid;
  };

  // Function to handle modal form submission (Save changes)
  const handleSaveChanges = async () => {
    var canClose=true;
    if(validateInputs())
    {
      const newParticipant = {
        name: modelname,
        status: modelStatus,
        email: modelEmai,
        country_code:modelCountryCode,
        phone:modelPhone,
        comment: modelComment,
        tenant_id:modelTenant,
      };
  
      // Perform API call or state update to save changes
      const updatedParticipant = {
        ...selectedParticipant,
        name: modelname,
        email: modelEmai,
        status: modelStatus,
        country_code: modelCountryCode,
        phone: modelPhone,
        verified: modelVerified,
        comment:modelComment,
        tenant_id:modelTenant,
        user_id:modelUserId,
      };
      try {
          const token = localStorage.getItem("token");
          const currentPage = window.location.pathname;
          const config = {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
              'Page-Name': currentPage,
            },
          };
        if (isNewRecord) {
          // Save new record
          const response = await axios.post(`api/participants/create`, newParticipant, config);
          setData((prevData) => [...prevData, response.data.participants]);
          if(response.status === 201)
            setSuccess(response.data.msg);
        } else {
          const prevData = await axios.patch(`api/participants/update/participants_id=${selectedParticipant.participants_id}`, updatedParticipant, config);
          // Update the user data in the state after successful save
          setData((prevData) =>
            prevData.map((participant) =>
              participant.participants_id === selectedParticipant.participants_id ? updatedParticipant : participant
            )
          );
          if(prevData.status === 200)
            setSuccess(prevData.data.msg);
        }
  
      } catch (error) {
        canClose=false;
        if (error.response && error.response.data && typeof error.response.data.error === 'string') {
          setError(error.response.data.error );
          setModelError(error.response.data.error );
        }
        if(error.status === 401)
          navigate('/logout');
      }
      if(canClose)
      {
        setIsViewEditModalOpen(false); // Close the modal after saving
        setSelectedParticipants(null);
        setIsNewRecord(false);
      }
    }
  };

  // Render loading spinner or error message
  if (loading) {
    return <div>Loading...</div>;
  }
  const columns = [
    {
      field: 'actions',
      headerName: getString('GLAC'),
      width: 200,
      renderCell: (params) => (
        <>
          {/* View Icon */}
          <IconButton
            onClick={() => handleViewClick(params.row)}
            aria-label="view"
            sx={{ color: '#2e97ff' }}
          >
            <VisibilityIcon />
          </IconButton>
          
          {/* Edit Icon */}
          <IconButton
            onClick={() => handleEditClick(params.row)}
            aria-label="edit"
            sx={{ color: '#2e97ff'}}
          >
            <EditIcon />
          </IconButton>

          {/* Delete Icon */}
          <IconButton
            onClick={() => handleDeleteClick(params.row)}
            aria-label="delete"
            sx={{ color: '#2e97ff'}}
          >
            <DeleteIcon />
          </IconButton>

        </>
      ),
    },

    { field: 'name', headerName: getString('GLNM'), width: 230, editable: false },
    { field: 'email', headerName: getString('GLEM'), width: 200, editable: false },
    { field: 'status', headerName: getString('GLS'), width: 160, editable: true },
    { field: 'user_id', headerName: getString('GLCR'), width: 150, editable: false },
    { field: 'phone', headerName: getString('GLPH'), width: 200, editable: false },
  ];

  // Convert the data object into an array for DataGrid rows
  const rows = data.map((participant, index) => ({
    id: participant.participants_id,                                          
    email: participant.email,              
    name: participant.name,
    comment: participant.comment,            
    status: getCodeDescription(participant.status,statuses),
    hStatus: participant.status, 
    hCountryCode: participant.country_code,
    countryCode: getCodeDescription(participant.country_code,countryCodes),
    phone: participant.phone,
    user_id: getCodeDescription(participant.user_id,userNames),
    hUser_id: participant.user_id,
    hVerified: null?0:participant.verified,      
    create_date: getFormatedDate(participant.create_date),
    last_update_date: getFormatedDate(participant.last_update_date), 
    tenant_id: getCodeDescription(participant.tenant_id,tenants),
    htenant_id: participant.tenant_id,
    participants_id: participant.participants_id
  }));

  // Handle form submit to set filters
  const handleFilterChange = (e) => {
    if(e.target.name === 'to_date' && e.target.value < filters.from_date)
    {
      alert(getString('GLDC'));
      return;
    }
    if(e.target.name === 'from_date' && e.target.value > filters.to_date)
    {
      alert(getString('GLDC'));
      return;
    }
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleVerifiedChange = (event, checked) => {
    setModelVerified(checked);
  };
  
  return (
    <Container>
      <CssBaseline enableColorScheme />
      <Typography variant="h4" gutterBottom>{getString('AQPL')}</Typography>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1-content" id="panel1-header">
           <Typography>{getString('GLFL')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <FormGrid size={{ xs: 12, md: 4 }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                <TextField
                  label={getString('GLFR')}
                  type="date"
                  name="from_date"
                  value={filters.from_date}
                  onChange={handleFilterChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </FormControl>
            </FormGrid>
            
            <FormGrid size={{ xs: 12, md: 3 }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                <TextField
                    label={getString('GLTO')}
                    type="date"
                    name="to_date"
                    value={filters.to_date}
                    onChange={handleFilterChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
              </FormControl>
            </FormGrid>

            <FormGrid size={{ xs: 12, md: 4 }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <TextField select label={getString('GLS')} name="status" value={filters.status} onChange={handleFilterChange} fullWidth >
                    {statuses.map((dd) => (
                      <MenuItem key={dd.code} value={dd.code}>
                        {dd.description}
                      </MenuItem>
                    ))}
                </TextField>
              </FormControl>
            </FormGrid>
          </Grid>  
        </AccordionDetails>
      </Accordion>

      <IconButton color="primary" onClick={() => handleAddClick()}> <AddIcon fontSize="large"/> </IconButton>
      
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick

              sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold', // Bold font for the header titles
                }
              }}
            />
          </Box>      
        </Grid>       
      </Grid>
      {error && <MessageSection error={getString(error)} />}
      {info && <MessageSection info={getString(info)} />}
      {success && <MessageSection success={getString(success)} />}

      {/* View/ Edit User Modal */}
      <Dialog open={isViewEditModalOpen} onClose={() => setIsViewEditModalOpen(false)}>
        <DialogTitle>{modeTitle}</DialogTitle>
        <DialogContent>
          <TextField
            label={getString('GLNM')}
            fullWidth
            margin="normal"
            value={modelname}
            name="name"
            id="name"
            required
            onChange={(e) => setModelName(e.target.value)}
            InputProps={{ readOnly: isReadOnly,}}
            error={nameError}
            helperText={nameErrorMessage}
            color={nameError ? 'error' : 'primary'}
            
          />
      
          <TextField
            label={getString('GLEM')}
            fullWidth
            margin="normal"
            value={modelEmai}
            name="email"
            id="email"
            required
            onChange={(e) => setModelEmail(e.target.value)}
            InputProps={{ readOnly: isReadOnly,}}
            error={emailError}
            helperText={emailErrorMessage}
            color={emailError ? 'error' : 'primary'}
          />

          <TextField select label={getString('GLCC')} fullWidth  margin="normal" value={modelCountryCode} onChange={(e) => setModelCountryCode(e.target.value)} InputProps={{ readOnly: isReadOnly,}}>
            {countryCodes.map((dd) => (
              <MenuItem key={dd.code} value={dd.code}>
                {dd.description}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label={getString('GLPH')}
            fullWidth
            margin="normal"
            value={modelPhone}
            name="phone"
            id="phone"
            onChange={(e) => setModelPhone(e.target.value)}
            InputProps={{ readOnly: isReadOnly,}}
            error={phoneError}
            helperText={phoneErrorMessage}
            color={phoneError ? 'error' : 'primary'}
          />

          <TextField required select label={getString('GLS')} fullWidth  margin="normal" value={modelStatus} name="status" id="status" onChange={(e) => setModelStatus(e.target.value)} InputProps={{ readOnly: isReadOnly,}}>
            {statuses.map((dd) => (
              <MenuItem key={dd.code} value={dd.code}>
                {dd.description}
              </MenuItem>
            ))}
          </TextField>
          <TextField required name='tenant' id='tenant' select label={getString('GLTN')} fullWidth margin="normal" value={modelTenant} onChange={(e) => setModelTenant(e.target.value)} InputProps={{ readOnly: isReadOnly,}}>
            {tenants.map((dd) => (
              <MenuItem key={dd.code} value={dd.code}>
                {dd.description}
              </MenuItem>
            ))}
          </TextField>

        
            {!isNewRecord && <Switch label={getString('GLVF')} name="modelVerified" checked={modelVerified} onChange={handleVerifiedChange} inputProps={{ 'aria-label': 'controlled' }} />}
            <TextField label={getString('GLCM')} fullWidth margin="normal" value={modelComment} onChange={(e) => setModelComment(e.target.value)} multiline rows={4} InputProps={{ readOnly: isReadOnly,}} />
            {isReadOnly && <TextField label={getString('GLCD')} fullWidth margin="normal" value={modelCreated} disabled='true' />}
            {isReadOnly && <TextField label={getString('GLLU')} fullWidth margin="normal" value={modelLastUpdate} disabled='true' />}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsViewEditModalOpen(false)}>{getString('GNCL')}</Button>
          {!isReadOnly && ( <Button onClick={handleSaveChanges} variant="contained">  {getString('GLSV')} </Button> )}
        </DialogActions>
        {modelError && <MessageSection error={getString(modelError)} />}
      </Dialog>
        
    </Container>
  );
};
export default SharingGroupParticipantsListPage;
