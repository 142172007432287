import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Button, CssBaseline, Container, FormControl, FormLabel, Select, MenuItem, OutlinedInput, Stack, Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Slider from '@mui/material/Slider';
import {fetchCodeDescriptionMap} from '../Utility/getCodeDescription';
import {getString} from '../Utility/getSystemString';
import MessageSection from '../common/message';
import { getUserRoleFromToken } from "../Utility/tokenParsar";

const MaxQN = 100;
const MinQN = 1;
const questionNums = [
  {
    value: MinQN,
    label: '',
  },
  {
    value: MaxQN,
    label: '',
  },
];

const MaxDuration = 300;
const MinDuration = 1;
const durations = [
  {
    value: MinDuration,
    label: '',
  },
  {
    value: MaxDuration,
    label: '',
  },
];

const QuizGenerator = () => {
  const [loading, setLoading] = useState(false);  // Add loading state
  const navigate = useNavigate();
 
  const [complexities, setComplexities] = useState([]);
  const [categories, setCategories] = useState([]); 
  const [languages, setLanguages] = useState([]); 
  const [statuses, setStatuses] = useState([]);  
  
  const [file, setFile] = useState(null);
  const [selectedComplexity, setSelectedComplexity] = useState('');
  const [selectedQuizCategory, setSelectedQuizCategory] = useState('');
  const [selectedQuizLanguage, setSelectedQuizLanguage] = useState(''); 
  const [selectedStatus, setSelectedStatus] = useState('');
  const [subject, setSubject] = useState("");
  const [valQuestNum, setQuestNumVal] = React.useState(MinQN);
  const [valDuration, setDurationVal] = React.useState(60);
  
  const [fileError, setFileError] = React.useState(false);
  const [fileErrorMessage, setFileErrorMessage] = React.useState('');
  const [numQuestionError, setNumQuestionError] = useState([]);
  const [numQuestionErrorMessage, setNumQuestionErrorMessage] = useState([]);
  const [error, setError] = useState(null);  

  // Fetch complexities, quizLanguage and quizCategory from the backend on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const statusesData = await fetchCodeDescriptionMap('statuses');
        setStatuses(statusesData);

        const complexitiesData = await fetchCodeDescriptionMap('complexities');
        setComplexities(complexitiesData);

        const languagesData = await fetchCodeDescriptionMap('quiz_languages');
        setLanguages(languagesData);

        const CategoriesData = await fetchCodeDescriptionMap('quiz_categories');
        setCategories(CategoriesData);

        setNumQuestionError(false);
        setNumQuestionErrorMessage('');
        setNumQuestionError(false);
        setNumQuestionErrorMessage('');

      } catch (error) {
        if (error.response && error.response.data && typeof error.response.data.error === 'string')
          setError(error.response.data.error );
        if(error.status === 401)
          navigate('/logout');
      }
    };
    fetchData();
  }, [navigate]);

  const handleQuizGeneration = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append("subject", subject);
      formData.append("num_questions", valQuestNum);
      formData.append("complexity", selectedComplexity);
      formData.append("quiz_category_code", selectedQuizCategory);
      formData.append("quiz_language_code", selectedQuizLanguage);
      formData.append("status", selectedStatus);
      formData.append("duration", valDuration);
      const token = localStorage.getItem("token");
      setLoading(true);  // Set loading to true when the process starts
      const currentPage = window.location.pathname; // Get the current page or route
      await axios.post('api/quiz/generate', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Page-Name': currentPage,
        }
      }).then(response => {
        
        const quiz_id = response.headers['quiz_id']
        // Navigate to the results page with the response data
        navigate('/quizform', { state: { data: response.data, quiz_id} });
      })
      .catch(error => {
        if (error.response && error.response.data && typeof error.response.data.error === 'string')
          setError(error.response.data.error );
        if(error.status === 401)
          navigate('/logout');
      });

    } catch (error) {
      if (error.response && error.response.data && typeof error.response.data.error === 'string')
        setError(error.response.data.error );
    }
    finally {
      setLoading(false);  // Set loading to false when the process is complete
    }

    
  };

  const handlFileChange = (e) => {
    setError(false);
    setFileError(false);
    setFileErrorMessage('');
    const selectedFile = e.target.files[0];
    const maxSizeInBytes =  1024 * 1024;
    
    if (selectedFile) {
      // Check if the file is an image
      const fileType = selectedFile.type;
      const supportedFileType = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword',  'application/vnd.oasis.opendocument.text',  'text/rtf', 'text/plain','application/pdf','image/jpeg','image/jpg','image/png'];

      if (!supportedFileType.includes(fileType)) {
        setFileError(true);
        setFileErrorMessage(getString('UFFE'));
        e.target.value = ''; // Clear the file input field
        setFile(null);
        return;
      }
      if (getUserRoleFromToken() === 'ROV' && selectedFile.size > maxSizeInBytes) {
        setFileError(true);
        setFileErrorMessage(getString('ERUF'));
        e.target.value = ''; // Clear the file input field
        setFile(null);
        return;
      }
      // If file type and size are valid, reset errors and store the file
      setFileError(false);
      setFileErrorMessage('');
      setFile(selectedFile);
    }
    else{
      alert(getString('UFFE'));
      setFileError(true);
      setFileErrorMessage(getString('UFFE'));
      setFile(null);
    }
  };

  const handleQuestionNumberChange = (_, newValue) => {
    setNumQuestionError(false);
    setNumQuestionErrorMessage('');
    if(getUserRoleFromToken() === 'ROV' && newValue > 5)
    {
      setNumQuestionError(true);
      setNumQuestionErrorMessage(getString('ERQF'));
      return;
    }
    setNumQuestionError(false);
    setNumQuestionErrorMessage('');
    setQuestNumVal(newValue);
    
  };

  const handleDurationChange = (_, newValue) => {
    setDurationVal(newValue);
  };

  return (
    <Container direction="column" justify="space-between">
        <CssBaseline enableColorScheme />
        <Stack sx={{ justifyContent: 'top', height: '100dvh', p: 2,}}>             
          <Typography variant="h4" gutterBottom>{getString('TCQZ')}</Typography>

          <Box component="form" onSubmit={handleQuizGeneration} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <FormControl>
                <FormLabel htmlFor="fileUpload" required>{getString('QLUF')}</FormLabel>
                  <OutlinedInput
                    id="fileUpload" name="fileUpload" required size="small"
                    onChange={handlFileChange}
                    type="file"
                    placeholder="fileUpload"
                    autoComplete="fileUpload"
                    error={fileError} 
                    helperText={fileErrorMessage} 
                    color={fileError ? 'error' : 'primary'}
                  />
                  {fileError && <MessageSection error={fileErrorMessage}/>}
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="selectedNumQuestion" required>{getString('GLNQ')}</FormLabel>
                  <Slider
                    id="selectedNumQuestion"
                    name="selectedNumQuestion"
                    marks={questionNums}
                    step={1}
                    value={valQuestNum}
                    valueLabelDisplay="auto"
                    min={MinQN}
                    max={MaxQN}
                    onChange={handleQuestionNumberChange}
                    error={numQuestionError} 
                    helperText={numQuestionErrorMessage} 
                    color={numQuestionError ? 'error' : 'primary'}
                  />  
                  {numQuestionError && <MessageSection error={numQuestionErrorMessage}/>}
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="duration" required>{getString('QGDR')}</FormLabel>
                  <Slider
                    id="duration"
                    name="duration"
                    marks={durations}
                    step={15}
                    value={valDuration}
                    valueLabelDisplay="auto"
                    min={MinDuration}
                    max={MaxDuration}
                    onChange={handleDurationChange}
                  /> 
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="subject">{getString('GLSB')}</FormLabel>
                  <OutlinedInput
                    id="subject"
                    name="subject"
                    type="subject"
                    placeholder={getString('GLSB')}
                    autoComplete="subject"
                    onChange={(e) => setSubject(e.target.value)}
                    required
                    size="small"
                  />
                </FormControl>
                
                <Grid container spacing={3}>
                  <Grid size={{ xs: 'grow', md: 6 }}>
                  <FormControl variant="standard" fullWidth>
                    <FormLabel htmlFor="complexity" required>{getString('GLCX')}</FormLabel>
                    <Select
                        labelId="complexity"
                        id="complexity"
                        value={selectedComplexity}
                        label={getString('GLCX')}
                        required
                        onChange={(e) => setSelectedComplexity(e.target.value)}
                    >
                      <MenuItem value=""><em>{getString('GQSC')}</em></MenuItem>
                      {complexities.map((dd) => (
                        <MenuItem key={dd.code} value={dd.code}>
                          {dd.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  </Grid>
                  <Grid size={{ xs: 'grow', md: 6 }}>
                    <FormControl variant="standard" fullWidth>
                      <FormLabel htmlFor="quiz_category_code" required>{getString('GLCT')}</FormLabel>
                      <Select
                        labelId="quiz_category_code"
                        id="quiz_category_code"
                        value={selectedQuizCategory}
                        label="quiz_category_code"
                        required
                        onChange={(e) => setSelectedQuizCategory(e.target.value)}
                      >
                        <MenuItem value=""><em>{getString('GQCS')}</em></MenuItem>
                        {categories.map((dd) => (
                          <MenuItem key={dd.code} value={dd.code}>{dd.description}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid size={{ xs: 'grow', md: 6 }}>
                    <FormControl variant="standard" fullWidth>
                      <FormLabel htmlFor="quiz_language_code" required>{getString('GLLN')}</FormLabel>
                      <Select
                          labelId="quiz_language_code"
                          id="quiz_language_code"
                          value={selectedQuizLanguage}
                          label={getString('GLLN')}
                          required
                          onChange={(e) => setSelectedQuizLanguage(e.target.value)}
                        >
                          <MenuItem value=""><em>{getString('GQSL')}</em></MenuItem>
                          {languages.map((dd) => (<MenuItem key={dd.code} value={dd.code}>{dd.description}</MenuItem>))}
                        </Select>
                    </FormControl>
                  </Grid>
                  <Grid size={{ xs: 'grow', md: 6 }}>
                    <FormControl variant="standard" fullWidth>
                      <FormLabel htmlFor="status" required>{getString('GLS')}</FormLabel>
                      <Select
                        labelId="status"
                        id="status"
                        value="ACTV"
                        label={getString('GLS')}
                        required
                        disabled="true"
                        onChange={(e) => setSelectedStatus(e.target.value)}
                      >
                        <MenuItem value=""><em>{getString('GSS')}</em></MenuItem>
                        {statuses.map((dd) => (<MenuItem key={dd.code} value={dd.code}>{dd.description}</MenuItem>))}
                      </Select>
                    </FormControl> 
                  </Grid>

                  {error && <MessageSection error={getString(error)} />}

                </Grid>

                <Grid container spacing={3}>
                  <Grid size={{ xs: 'grow', md: 12 }} offset="auto">
                  <FormControl variant="standard">  
                    <Box sx={{ '& button': { m: 1 } }}>                                  
                      {/* If loading, show CircularProgress, otherwise show Save button */}
                      {
                        loading ? (<CircularProgress size={24}/>) : 
                        (
                          <Button variant="contained" size="small" type="submit" color="primary" > {getString('GQGQ')} </Button>
                        )
                      }
                    </Box>   
                  </FormControl> 
                    
                  </Grid>
                </Grid>
          </Box>
        </Stack>
    
    </Container>
  );
};

export default QuizGenerator;