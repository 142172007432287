import * as React from 'react';
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, CssBaseline, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, TextField, Typography, MenuItem } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import {getDefaultDateRange, getFormatedDate} from '../Utility/dateUtils';
import {fetchUserNames, fetchQuizNames, fetchCodeDescriptionMap, getCodeDescription} from '../Utility/getCodeDescription';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {getString} from '../Utility/getSystemString';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MessageSection from '../common/message';


const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));
// Now call the function after it's defined
const { formattedToDate, formattedLastWeek } = getDefaultDateRange();
const CostReportPage = () => {
  const navigate = useNavigate();
  const [currencies, setCurrencies] = useState([]);
  const [data, setData] = useState([]);  
  const [loading, setLoading] = useState(true);  
  const [error, setError] = useState(null); 
  const [modelError, setModelError] =useState(null);
  const [users, setUserNames] = useState([]);
  const [quizNames, setQuizNames] = useState([]);

    //model popup feilds
    const [isViewEditModalOpen, setIsViewEditModalOpen] = useState(false); 
    const [modelUser, setModelUser] = useState('');
    const [modelQuiz, setModelQuiz] = useState(''); 
    const [modelTotalToken, setModelTotalToken] = useState('');
    const [modelPromptToken, setModelPromptToken] = useState('');
    const [modelResponselToken, setModelResponseToken] = useState('');
    const [modelCost, setModelCost] = useState('');
    const [modelCurrency, setModelCurrency] = useState('');
    const [modelComment, setModelComment] = useState('');
    const [modelCreated, setModelCreated] = useState(''); 
    const [modelLastUpdate, setModelLastUpdate] = useState(''); 
    const [modelTenant, setModelTenant] = useState(''); 
    const [isReadOnly, setIsReadOnly] = useState(false); 

  const [filters, setFilters] = useState({
    create_date_from: formattedLastWeek,
    create_date_to: formattedToDate,
    user_id:''
  });

  useEffect(() => {
    // Function to fetch data from the backend
    const fetchData = async () => {
      try {
        setError('')
        setModelError('');
        const token = localStorage.getItem("token");
        const currentPage = window.location.pathname; // Get the current page or route
        // Set up the headers including Authorization and Page-Name
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Page-Name': currentPage,  // Pass the current page name in a custom header
          },
        };
        const apiUrl = `api/report/appusages?user_id=${filters.user_id}&create_date_from=${filters.create_date_from}&create_date_to=${filters.create_date_to}`;
        const response = await axios.get(apiUrl, config);

        setData(response.data.app_usage);  // Set the fetched data to state

        const currenciesData = await fetchCodeDescriptionMap('currency');
        setCurrencies(currenciesData);

        const quizNameData = await fetchQuizNames();
        setQuizNames(quizNameData);

        const userNameData = await fetchUserNames();
        setUserNames(userNameData);

      } catch (error) {
          if (error.response && error.response.data && typeof error.response.data.error === 'string') {
            setError(error.response.data.error );
          }
          if(error.status === 401)
            navigate('/logout');
          console.error('Error fetching data: ', error);
        } finally {
          setLoading(false);  // Stop the loading spinner
        }
    };

    fetchData();
  }, [filters, navigate]);

  // Handle click for "View" (read-only mode)
  const handleViewClick = (app_usage) => {
    setModelError('');
    setModelQuiz(app_usage.quiz_id); 
    setModelUser(app_usage.user_id); 
    setModelTotalToken(app_usage.total_tokens); 
    setModelResponseToken(app_usage.completion_tokens); 
    setModelComment(app_usage.comment);
    setModelPromptToken(app_usage.prompt_tokens);
    setModelCost(app_usage.cost);
    setModelCurrency(app_usage.currency)
    setModelCreated(app_usage.create_date);
    setModelTenant(app_usage.tenant_id);
    setModelLastUpdate(app_usage.last_update_date);
    setIsReadOnly(true);  // Open modal in read-only mode
    setIsViewEditModalOpen(true);  // Open the modal
  };

  // Function to handle modal form submission (Save changes)
  const handleSaveChanges = async () => {
    
  };

  // Render loading spinner or error message
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>{error}</div>;
  }

  const columns = [
    {
      field: 'actions',
      headerName: getString('GLAC'),
      width: 100,
      renderCell: (params) => (
        <>
          {/* View Icon */}
          <IconButton
            onClick={() => handleViewClick(params.row)}
            aria-label="view"
            sx={{ color: '#2e97ff' }}
          >
            <VisibilityIcon />
          </IconButton>
        </>
      ),
    },
    { field: 'user_id', headerName: getString('GLCR'), width: 230, editable: false },
    { field: 'quiz_id', headerName: getString('GLSB'), width: 210, editable: false },
    { field: 'total_tokens', headerName: 'Total Token', width: 200, editable: true },
    { field: 'cost', headerName: 'Cost', width: 200, editable: false },
    { field: 'currency', headerName: 'Currency', width: 200, editable: false },
  ];

  // Convert the data object into an array for DataGrid rows
  const rows = data.map((app_usage, index) => ({
    id: app_usage.application_usage_id,                      // A unique ID for each row                     
    user_id: getCodeDescription(app_usage.user_id,users),              
    quiz_id: getCodeDescription(app_usage.quiz_id,quizNames),            
    total_tokens: app_usage.total_tokens,
    prompt_tokens: app_usage.prompt_tokens,              
    completion_tokens: app_usage.completion_tokens,
    cost: app_usage.cost,
    currency: getCodeDescription(app_usage.currency,currencies),              
    comment: app_usage.comment,            
    create_date: getFormatedDate(app_usage.create_date),
    last_update_date: getFormatedDate(app_usage.last_update_date), 
    tenant_id: app_usage.tenant_id 
  }));

  // Handle form submit to set filters
  const handleFilterChange = (e) => {
    if(e.target.name === 'create_date_to' && e.target.value < filters.create_date_from)
    {
      alert(getString('GLDC'));
      return;
    }
    if(e.target.name === 'create_date_from' && e.target.value > filters.create_date_to)
    {
      alert(getString('GLDC'));
      return;
    }
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  return (
    <Container>
        <CssBaseline enableColorScheme />
        <Typography variant="h4" gutterBottom>{getString('CRPT')}</Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1-content" id="panel1-header">
            <Typography>{getString('GLFL')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              <FormGrid size={{ xs: 12, md: 3 }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <TextField
                    label={getString('GLFR')}
                    type="date"
                    name="create_date_from"
                    value={filters.create_date_from}
                    onChange={handleFilterChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </FormControl>
              </FormGrid>
              
              <FormGrid size={{ xs: 12, md: 3 }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <TextField
                      label={getString('GLTO')}
                      type="date"
                      name="create_date_to"
                      value={filters.create_date_to}
                      onChange={handleFilterChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                </FormControl>
              </FormGrid>

              <FormGrid size={{ xs: 12, md: 3 }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <TextField select label={getString('GLCR')} name="user_id" value={filters.user_id} onChange={handleFilterChange} fullWidth >
                    {users.map((user) => (
                      <MenuItem key={user.code} value={user.code}>
                        {user.description}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </FormGrid>

              <FormGrid size={{ xs: 12, md: 3 }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 160 }}>
                
                </FormControl>
              </FormGrid>
            </Grid> 
          </AccordionDetails>
        </Accordion>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box>
              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick

                sx={{
                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold', // Bold font for the header titles
                  }
                }}
              />
            </Box>      
          </Grid> 
          {error && <MessageSection error={getString(error)} />}               
        </Grid>

        {/* View  Modal */}
        <Dialog open={isViewEditModalOpen} onClose={() => setIsViewEditModalOpen(false)}>
          <DialogTitle>{isReadOnly ? getString('CRCD') : getString('CREC')}</DialogTitle>
            <DialogContent>
              <TextField label={getString('GLSB')} fullWidth margin="normal" value={modelQuiz} InputProps={{ readOnly: isReadOnly,}} disabled='true' />
              <TextField label={getString('CRCO')} fullWidth margin="normal" value={modelCost} onChange={(e) => setModelCost(e.target.value)} InputProps={{ readOnly: isReadOnly,}} />
              <TextField label={getString('CRCR')} fullWidth margin="normal" value={modelCurrency} onChange={(e) => setModelCurrency(e.target.value)} InputProps={{ readOnly: isReadOnly,}} />
              <TextField label={getString('CRTT')} fullWidth margin="normal" value={modelTotalToken} onChange={(e) => setModelTotalToken(e.target.value)} InputProps={{ readOnly: isReadOnly,}} />
              <TextField label={getString('CRP')}  fullWidth margin="normal" value={modelPromptToken} onChange={(e) => setModelPromptToken(e.target.value)} InputProps={{ readOnly: isReadOnly,}} />
              <TextField label={getString('CRRT')} fullWidth margin="normal" value={modelResponselToken} onChange={(e) => setModelResponseToken(e.target.value)} InputProps={{ readOnly: isReadOnly,}} />
              <TextField label={getString('GLCM')} fullWidth margin="normal" value={modelComment} onChange={(e) => setModelComment(e.target.value)} multiline rows={4} InputProps={{ readOnly: isReadOnly,}} />
              <TextField label={getString('GLCR')} fullWidth margin="normal" value={modelUser} disabled='true' />
              <TextField label={getString('QSSO')} fullWidth margin="normal" value={modelCreated} disabled='true' />
              <TextField label={getString('GLLU')} fullWidth margin="normal" value={modelLastUpdate} disabled='true' />
              <TextField label={getString('GLTN')} fullWidth margin="normal" value={modelTenant} disabled='true' />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsViewEditModalOpen(false)}>{getString('GNCL')}</Button>
              {!isReadOnly && (
                <Button onClick={handleSaveChanges} variant="contained">
                  {getString('GLSV')}
                </Button>
              )}
            </DialogActions>
            {modelError && <MessageSection error={getString(modelError)} />}
          </Dialog>

    </Container>
);
};

export default CostReportPage;
