import * as React from 'react';
import { useState } from "react";
import { useLocation } from 'react-router-dom';
import { Box, Button, CircularProgress, CssBaseline, Container, FormControl, Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DataGrid } from '@mui/x-data-grid';
import ModalForm from '../common/modalform';
import SendIcon from '@mui/icons-material/Send';
import {handelPdfDownload, handelWordDownload, handelExcelDownload} from '../Utility/download';
import {getString} from '../Utility/getSystemString';

const QuizForm = () => {
  const location = useLocation();
  //const navigate = useNavigate();
  const [loading, setLoading] = useState(false);  // Add loading state
  const data = location.state?.data || {};
  const quiz_id = location.state.quiz_id;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSave = () => {
    console.log("Data saved successfully!");
    // You can add additional logic here after the modal data is saved
    setIsModalOpen(false); // Close the modal after saving
  };

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  const columns = [
    { field: 'No', headerName: getString('QLQN'), width: 50, editable: false,},
    { field: 'Question', headerName: getString('QLQU'), width: 450, editable: false,},
    { field: 'Options', headerName: getString('QLRS'), width: 400, editable: false,},
    { field: 'Answer', headerName: getString('QLCR'), width: 100, editable: false,}
  ];

  // Convert the data object into an array for DataGrid rows
  const rows = Object.keys(data).map((key) => ({
    id: key,                    
    No: data[key].No,             
    Question: data[key].Question,            
    Options: data[key].Options,
    Answer: data[key].Answer.toUpperCase() 
  }));
  
  //Todo handel grid data download
  const handlQuizFormSubmit = (event) => {
    event.preventDefault();  // Prevent the page from reloading
    
  };

  return (
      <Container>
  
         <CssBaseline enableColorScheme />
         <Typography variant="h4" gutterBottom>{getString('QLT2')}</Typography>
         <Box component="form" onSubmit={handlQuizFormSubmit} >

         
         <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box>
              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
                sx={{
                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold', // Bold font for the header titles
                  }
                }}
              />
            </Box>
          </Grid>

          <Grid size={{ xs: 'grow', md: 3 }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 190 }}>
              <Box sx={{ '& button': { m: 1 } }}>                            
                {
                  loading ? (<CircularProgress size={24}/>) : 
                  (
                    <Button variant="contained" size="small" 
                    onClick={() => handelPdfDownload(setLoading, quiz_id)}>{getString('GLDP')}</Button>
                  )
                }
              </Box> 
            </FormControl>
          </Grid>
          <Grid size={{ xs: 'grow', md: 3 }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 170 }}>
              <Box sx={{ '& button': { m: 1 } }}>                            
                {
                  loading ? (<CircularProgress size={24}/>) : 
                  (
                    <Button variant="contained" size="small" onClick={() => handelExcelDownload(setLoading, quiz_id)}>{getString('GLDE')}</Button>
                  )
                }
              </Box> 
            </FormControl> 
          </Grid>
          <Grid size={{ xs: 'grow', md: 3 }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 170 }}>
              <Box sx={{ '& button': { m: 1 } }}>
                {
                  loading ? (<CircularProgress size={24}/>) : 
                  (
                    <Button variant="contained" size="small" onClick={() => handelWordDownload(setLoading, quiz_id)}>{getString('GLDW')}</Button>
                  )
                }
              </Box> 
            </FormControl>
          </Grid>
          <Grid size={{ xs: 'grow', md: 3 }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 190 }}>
              <Box sx={{ '& button': { m: 1 } }}>
                <Button variant="contained" size="small" onClick={openModal} endIcon={<SendIcon />}>{getString('GLSQ')}</Button>
                  {isModalOpen && (
                    <ModalForm
                    onSave={handleSave}
                    token={localStorage.getItem("token")} // Pass the JWT token
                    id ={quiz_id}
                    apiUrl="api/sharing/create"  // Pass the API endpoint
                  />
                  )}
              </Box> 
            </FormControl>
          </Grid>
        </Grid>
        </Box>
    </Container>
  );
}
export default QuizForm;