import * as React from 'react';
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { Container, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import FormControl from '@mui/material/FormControl';
import {getDefaultDateRange, getFormatedDate} from '../Utility/dateUtils';
import {fetchUserNames, getCodeDescription, fetchQuizNames, fetchTenantNames} from '../Utility/getCodeDescription';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {getString} from '../Utility/getSystemString';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MessageSection from '../common/message';

const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));
const { formattedToDate, formattedFromDate } = getDefaultDateRange();


const QuizQuestListPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);  // State to hold the data from the backend
  const [loading, setLoading] = useState(true);  // State to handle loading
  const [error, setError] = useState(null);  
  const [info, setInfo] = useState(null);  
  const [success, setSuccess] = useState(null);  
  const [modelError, setModelError] = useState(null);
  const [users, setUserNames] = useState([]);
  const [quizNames, setQuizNames] = useState([]);
  const [tenants, setTenants] = useState([]);

  //model popup feilds
  const [selectedQuestion, setSelectedQuestion] = useState(null); // State to store the selected Question for editing
  const [isViewEditModalOpen, setIsViewEditModalOpen] = useState(false); // Modal visibility state
  const [modelSubject, setModelSubject] = useState(''); 
  const [modelUser, setModelUser] = useState(''); 
  const [modelQuestion, setModelQuestion] = useState('');
  const [modelQuestionNumber, setModelQuestionNumber] = useState('');
  const [modelQuestionResponses, setModelQuestionResponses] = useState('');
  const [modelQuestionCorrectRes, setModelQuestionCorrectRes] = useState('');
  const [modelQuestionScore, setModelQuestionScore] = useState('');
  const [modelCreated, setModelCreated] = useState(''); 
  const [modelLastUpdate, setModelLastUpdate] = useState(''); 
  const [modelTenant, setModelTenant] = useState(''); 
  const [isReadOnly, setIsReadOnly] = useState(false); 

  const [filters, setFilters] = useState({
    create_date_from: formattedFromDate,
    create_date_to: formattedToDate
  });

  useEffect(() => {
    // Function to fetch data from the backend
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const currentPage = window.location.pathname; // Get the current page or route
        // Set up the headers including Authorization and Page-Name
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Page-Name': currentPage,  // Pass the current page name in a custom header
          },
        };
        const apiUrl = `api/quiz/quizform/quizforms?create_date_from=${filters.create_date_from}&create_date_to=${filters.create_date_to}`;
        const response = await axios.get(apiUrl,config);
        setData(response.data.quiz_forms);  // Set the fetched data to state

        const userNameData = await fetchUserNames();
        setUserNames(userNameData);

        const quizNameData = await fetchQuizNames();
        setQuizNames(quizNameData);

        const tenantData = await fetchTenantNames();
        setTenants(tenantData);

      } catch (error) {
          if (error.response && error.response.data && typeof error.response.data.error === 'string') {
            setError(error.response.data.error );
          }
          if(error.status === 401)
            navigate('/logout');
          console.error('Error fetching data: ', error);
      } finally {
        setLoading(false);  // Stop the loading spinner
      }
    };

    fetchData();
  }, [filters, navigate]);

   // Handle click for "View" (read-only mode)
   const handleViewClick = (quiz_forms) => {
    setSelectedQuestion(quiz_forms); // Set the selected row for editing
    setModelSubject(quiz_forms.quiz_id); 
    setModelQuestion(quiz_forms.question); 
    setModelUser(quiz_forms.user_id); 
    setModelQuestionResponses(quiz_forms.response);
    setModelQuestionNumber(quiz_forms.question_number);
    setModelQuestionCorrectRes(quiz_forms.correct_answer);
    setModelQuestionScore(quiz_forms.score);
    setModelCreated(quiz_forms.create_date);
    setModelTenant(quiz_forms.tenant_id);
    setModelLastUpdate(quiz_forms.last_update_date);
    setIsReadOnly(true);  // Open modal in read-only mode
    setIsViewEditModalOpen(true);  // Open the modal
    setModelError(false);
    setError(false);
    setInfo(false);
    setSuccess(false);
  };

   // Function to handle Edit button click
   const handleEditClick = (quiz_forms) => {
    setSelectedQuestion(quiz_forms); // Set the selected user for editing
    setModelSubject(quiz_forms.quiz_id); 
    setModelQuestion(quiz_forms.question); 
    setModelUser(quiz_forms.user_id); 
    setModelQuestionResponses(quiz_forms.response);
    setModelQuestionNumber(quiz_forms.question_number);
    setModelQuestionCorrectRes(quiz_forms.correct_answer);
    setModelQuestionScore(quiz_forms.score);
    setModelCreated(quiz_forms.create_date);
    setModelTenant(quiz_forms.tenant_id);
    setModelLastUpdate(quiz_forms.last_update_date);
    setIsReadOnly(false);  // Open modal in read-only mode
    setIsViewEditModalOpen(true);  // Open the modal
    setModelError(false);
    setError(false);
    setInfo(false);
    setSuccess(false);
  };

  // Function to handle modal form submission (Save changes)
  const handleSaveChanges = async () => {
    // Perform API call or state update to save changes
    const updatedQuestion = {
      ...selectedQuestion,
      question: modelQuestion, 
      question_number: modelQuestionNumber,
      response: modelQuestionResponses,
      correct_answer: modelQuestionCorrectRes,
      score: modelQuestionScore
    };

    try {
      var canClose = true;
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      const prevData = await axios.patch(`api/quiz/quizform/update/quiz_form_id=${selectedQuestion.quiz_forms_id}`, updatedQuestion, config);
      if(prevData.status === 200)
        setSuccess(prevData.data.msg);
      // Update the user data in the state after successful save
      setData((prevData) =>
        prevData.map((question) =>
          question.quiz_forms_id === selectedQuestion.quiz_forms_id ? updatedQuestion : question
        )
      );
      
    } catch (error) {
      if (error.response && error.response.data && typeof error.response.data.error === 'string') {
        setError(error.response.data.error );
        setModelError(error.response.data.error );
        canClose = false;
      }
      if(error.status === 401)
        navigate('/logout');
    }

    if(canClose)
    {
      setIsViewEditModalOpen(false); // Close the modal after saving
      setSelectedQuestion(null);

    }
    
  };


  // Render loading spinner or error message
  if (loading) {
    return <div>Loading...</div>;
  }

  const columns = [
    {
      field: 'actions',
      headerName: getString('GLAC'),
      width: 100,
      renderCell: (params) => (
        <>
          {/* View Icon */}
          <IconButton
            onClick={() => handleViewClick(params.row)}
            aria-label="view"
            sx={{ color: '#2e97ff' }}
          >
            <VisibilityIcon />
          </IconButton>
          
          {/* Edit Icon */}
          <IconButton
            onClick={() => handleEditClick(params.row)}
            aria-label="edit"
            sx={{ color: '#2e97ff'}}
          >
            <EditIcon />
          </IconButton>
        </>
      ),
    },
    { field: 'quiz_id', headerName: getString('GLSB'), width: 180, editable: false },
    { field: 'user_id', headerName: getString('GLCR'), width: 150, editable: false },
    { field: 'question', headerName: getString('QLQU'), width: 360, editable: true },
    { field: 'response', headerName: getString('QLRS'), width: 350, editable: false },
  ];

  // Convert the data object into an array for DataGrid rows
  const rows = data.map((quiz_forms, index) => ({
    id: quiz_forms.quiz_forms_id,                      // A unique ID for each row                     
    quiz_id: getCodeDescription(quiz_forms.quiz_id, quizNames),              
    user_id: getCodeDescription(quiz_forms.user_id,users),            
    question: quiz_forms.question,
    question_number: quiz_forms.question_number,
    response: quiz_forms.response,
    correct_answer: quiz_forms.correct_answer,              
    score: quiz_forms.score,            
    create_date: getFormatedDate(quiz_forms.create_date),
    last_update_date: getFormatedDate(quiz_forms.last_update_date), 
    tenant_id: getCodeDescription(quiz_forms.tenant_id,tenants),
    quiz_forms_id: quiz_forms.quiz_forms_id 
  }));

  // Handle form submit to set filters
  const handleFilterChange = (e) => {
    if(e.target.name === 'create_date_to' && e.target.value < filters.create_date_from)
      {
        alert(getString('GLDC'));
        return;
      }
      if(e.target.name === 'create_date_from' && e.target.value > filters.create_date_to)
      {
        alert(getString('GLDC'));
        return;
      }
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };
  
  return (
    <Container>
        <CssBaseline enableColorScheme />
        <Typography variant="h4" gutterBottom>{getString('QQLT')}</Typography>
        <Accordion>
        <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1-content" id="panel1-header">
           <Typography>{getString('GLFL')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={3}>
            <FormGrid size={{ xs: 12, md: 3 }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                <TextField
                    label={getString('GLFR')}
                    type="date"
                    name="create_date_from"
                    value={filters.create_date_from}
                    onChange={handleFilterChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                />
              </FormControl>
            </FormGrid>
            <FormGrid size={{ xs: 12, md: 3 }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 10 }}>
                
              </FormControl>
            </FormGrid>

            <FormGrid size={{ xs: 12, md: 3 }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                <TextField
                      label={getString('GLTO')}
                      type="date"
                      name="create_date_to"
                      value={filters.create_date_to}
                      onChange={handleFilterChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
              </FormControl>
            </FormGrid>
          </Grid> 
        </AccordionDetails>
      </Accordion>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box>
              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
                sx={{
                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold', // Bold font for the header titles
                  }
                }}
              />
            </Box>      
          </Grid>                
        </Grid>
        {error && <MessageSection error={getString(error)} />}
        {info && <MessageSection info={getString(info)} />}
        {success && <MessageSection success={getString(success)} />}

        {/* View/ Edit Question Modal */}
        <Dialog open={isViewEditModalOpen} onClose={() => setIsViewEditModalOpen(false)}>
          <DialogTitle>{isReadOnly ? getString('QQQD') : getString('QQEQ')}</DialogTitle>
            <DialogContent>
              <TextField label={getString('GLSB')} fullWidth margin="normal" value={modelSubject} disabled='true' />
              <TextField label={getString('QLQU')}          fullWidth margin="normal" value={modelQuestion}           multiline rows={4} onChange={(e) => setModelQuestion(e.target.value)} InputProps={{ readOnly: isReadOnly,}} />
              <TextField label={getString('QLRS')}         fullWidth margin="normal" value={modelQuestionResponses}  multiline rows={4} onChange={(e) => setModelQuestionResponses(e.target.value)} InputProps={{ readOnly: isReadOnly,}} />            
              <TextField label={getString('QLCR')}  fullWidth margin="normal" value={modelQuestionCorrectRes} onChange={(e) => setModelQuestionCorrectRes(e.target.value)}  InputProps={{ readOnly: isReadOnly,}} />
              <TextField label={getString('QLQN')} fullWidth margin="normal" value={modelQuestionNumber}     onChange={(e) => setModelQuestionNumber(e.target.value)} InputProps={{ readOnly: isReadOnly,}} />
              <TextField label={getString('QLQS')}    fullWidth margin="normal" value={modelQuestionScore}      onChange={(e) => setModelQuestionScore(e.target.value)} InputProps={{ readOnly: isReadOnly,}} />
                
             
              <TextField label={getString('GLCR')} fullWidth margin="normal" value={modelUser} disabled='true' />
              <TextField label={getString('GLCD')} fullWidth margin="normal" value={modelCreated} disabled='true' />
              <TextField label={getString('GLLU')} fullWidth margin="normal" value={modelLastUpdate} disabled='true' />
              <TextField label={getString('GLTN')} fullWidth margin="normal" value={modelTenant} disabled='true' />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsViewEditModalOpen(false)}>{getString('GNCL')}</Button>
              {!isReadOnly && (
                <Button onClick={handleSaveChanges} variant="contained">
                  {getString('GLSV')}
                </Button>
              )}
            </DialogActions>
            {modelError && <MessageSection error={getString(modelError)} />}
        </Dialog>

    </Container>
);
};

export default QuizQuestListPage;
