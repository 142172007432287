import * as React from 'react';
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, CssBaseline, FormControl, MenuItem, TextField, Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import {fetchUserNames, fetchCodeDescriptionMap, getCodeDescription} from '../Utility/getCodeDescription';
import {getString} from '../Utility/getSystemString';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MessageSection from '../common/message';


const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));
// Now call the function after it's defined

const BillingReportPage = () => {
  const navigate = useNavigate();
  const [currencies, setCurrencies] = useState([]);
  const [users, setUserNames] = useState([]);
  const [data, setData] = useState([]);  // State to hold the data from the backend
  const [loading, setLoading] = useState(true);  // State to handle loading
  const [error, setError] = useState(null);  // State to handle errors
  const [filters, setFilters] = useState({
    user_id:''
  });

  useEffect(() => {
    // Function to fetch data from the backend
    const fetchData = async () => {
      try {
        setError('');
        const token = localStorage.getItem("token");
        const currentPage = window.location.pathname; // Get the current page or route

        // Set up the headers including Authorization and Page-Name
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Page-Name': currentPage,  // Pass the current page name in a custom header
          },
        };
        const apiUrl = `api/report/userbillingreport?user_id=${filters.user_id}`;
        
        const response = await axios.get(apiUrl,config);

        setData(response.data.report);  // Set the fetched data to state

        const currenciesData = await fetchCodeDescriptionMap('currency');
        setCurrencies(currenciesData);

        const userNameData = await fetchUserNames();
        setUserNames(userNameData);

      } catch (error) {
        if (error.response && error.response.data && typeof error.response.data.error === 'string') {
          setError(error.response.data.error );
        }
        if(error.status === 401)
          navigate('/logout');
      } finally {
        setLoading(false);  // Stop the loading spinner
      }
    };

    fetchData();
  }, [filters, navigate]);

  // Render loading spinner or error message
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>{error}</div>;
  }

  const columns = [
    { field: 'username', headerName: getString('GLNM'), width: 200, editable: false },
    { field: 'total_amount', headerName: getString('BLTA'), width: 200, editable: true },
    { field: 'total_token', headerName: getString('CRTT'), width: 200, editable: false },
    { field: 'paid', headerName: getString('BLP'), width: 150, editable: false },
    { field: 'out_standing', headerName: getString('BLOS'), width: 200, editable: false },
    { field: 'currency', headerName: getString('CRCR'), width: 200, editable: false },
  ];

  // Convert the data object into an array for DataGrid rows
  const rows = data.map((report, index) => ({
    id: report.user_id,                      // A unique ID for each row                                              
    username: report.username,
    total_amount: report.total_amount,              
    total_token: report.total_token,
    paid: report.paid,
    out_standing: report.out_standing,            
    currency: getCodeDescription(report.currency,currencies) 
  }));

  // Handle form submit to set filters
  const handleFilterChange = (e) => {
    
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  return (
    <Container>
        <CssBaseline enableColorScheme />
        <Typography variant="h4" gutterBottom>{getString('BRPT')}</Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1-content" id="panel1-header">
            <Typography>{getString('GLFL')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              <FormGrid size={{ xs: 12, md: 3 }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <TextField select label={getString('GLCR')} name="user_id" value={filters.user_id} onChange={handleFilterChange} fullWidth >
                      {users.map((user) => (
                        <MenuItem key={user.code} value={user.code}>
                          {user.description}
                        </MenuItem>
                      ))}
                    </TextField>
                </FormControl>
              </FormGrid>
              
              <FormGrid size={{ xs: 12, md: 3 }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  
                </FormControl>
              </FormGrid>

              <FormGrid size={{ xs: 12, md: 3 }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  
                </FormControl>
              </FormGrid>

              <FormGrid size={{ xs: 12, md: 3 }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 160 }}>
                
                </FormControl>
              </FormGrid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box>
              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick

                sx={{
                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold', // Bold font for the header titles
                  }
                }}
              />
            </Box>      
          </Grid>                
        </Grid>
        {error && <MessageSection error={getString(error)} />}
    </Container>
  );
};

export default BillingReportPage;
