import * as React from 'react';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AppProvider } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { getString } from '../Utility/getSystemString';
import { getUserData } from '../Utility/getSystemString';
import iconMap from './dashboardIconConfig';
import logo from '../assets/agkiyalogo.png';

function DashboardLayoutBasic(props) {
  const { window } = props;
  const [navigation, setNavigation] = useState([]);
  //const [userData, setUserData] = useState([]);
  const navigate = useNavigate();

  

  // Fetch navigation data from the backend
  useEffect(() => {
    const fetchNavigation = async () => {
      try {
        const token = localStorage.getItem("token");
        const currentPage = typeof window !== "undefined" ? window.location.pathname : "/";
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Page-Name': currentPage,
          },
        };
        const apiUrl = '/api/metadata/getsidebaritems';
        const response = await axios.get(apiUrl, config);

        const data = response.data.metadata.map(item => ({
          ...item,
          icon: iconMap[item.icon],
          title: getString(item.title_code),
        }));

        setNavigation(data);

        //const apiUserUrl = `api/user/users?user_id=${getUserIdFromToken()}`;
        //const userResponse = await axios.get(apiUserUrl, config);
        //setUserData(userResponse.data.users);

      } catch (error) {
        if (error.response && error.response.data && typeof error.response.data.error === 'string') {
        } else 
        if(error.status === 401) {
          navigate('/logout');
        }
      }
    };

    fetchNavigation();
  }, [navigate, window]);
  
  const contentWindow = window !== undefined ? window() : undefined;

  const [session, setSession] = React.useState({
    user: {
      name: getUserData().username,
      email: getUserData().login,
      image: getUserData().photo_url,
    },
  });

  const authentication = React.useMemo(() => {
    return {
      signIn: () => {
        setSession({
          user: {
            name: getUserData().username,
            email: getUserData().login,
            image: getUserData().photo_url,
          },
        });
      },
      signOut: () => {
        navigate('/logout');
      },
    };
  }, [navigate]);

  return (
    <AppProvider 
        navigation={navigation} 
        window={contentWindow}
        session={session}
        authentication={authentication}
        branding={{
          logo: <img src={logo} alt="Logo" style={{ height: '80pt', marginRight: '10px' }} />,
          title: 'Quiz Intello',
        }}
      >
      <DashboardLayout>
      <style>
          {`
            /* Customize this to not hide elements containing the logo */
            .css-1qsfemz, .css-feb0vu-MuiTypography-root {
              /* Display as necessary for header elements */
              display: inherit;
            }
          `}
        </style>
        <Outlet />
      </DashboardLayout>
    </AppProvider>
  );
}

DashboardLayoutBasic.propTypes = {
  window: PropTypes.func,
};

export default DashboardLayoutBasic;
