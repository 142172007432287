import React from "react";
import Grid from '@mui/material/Grid2';
import { Alert, Stack } from '@mui/material';
const MessageSection = ({info, success, warning, error}) => {
 
  return (
    <Grid container spacing={3}>
      {error && 
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity="error">{error}</Alert>
        </Stack>}  
        {success && 
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity="success">{success}</Alert>
        </Stack>}
        {warning && 
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity="warning">{warning}</Alert>
        </Stack>}
        {info && 
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity="info">{info}</Alert>
        </Stack>}        
    </Grid>
  );
};
export default MessageSection;
