import React, { useState, useEffect  } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {Alert, Box, Button, IconButton, MenuItem, Modal, Stack, TextField, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import Grid from '@mui/material/Grid2';
import {getString} from '../Utility/getSystemString';
import { isValidEmail } from "../Utility/validations";
import { fetchParticipantNames, fetchGroupNames} from '../Utility/getCodeDescription';


const ModalForm = ({ onSave, token, apiUrl, id }) => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([{ email_id: "", shared_user_name: "", participants_id:"", shared_group_id:"" }]); // Corrected key name for shared_user_name
  
  const [errors, setErrors] = useState([]);
  const [backendError, setBackendError] = useState("");
  const [groups, setGroups] = useState([]);
  const [participants, SetParticipants] = useState([]);

  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);

    const updatedErrors = [...errors];
    updatedErrors[index] = { ...updatedErrors[index], [field]: "" };
    setErrors(updatedErrors);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const groupsData = await fetchGroupNames();
        setGroups(groupsData);

        const participantsData = await fetchParticipantNames();
        SetParticipants(participantsData);

      } catch (error) {
        if(error.status === 401)
          navigate('/logout');
        console.error('Error fetching grops and participants:', error);
      }
    };
    fetchData();
  }, [navigate]);


  // Add a new row with empty fields for A and B
  const handleAddRow = () => {
    setRows([...rows, { email_id: "", shared_user_name: "", participants_id:"", shared_group_id:""  }]);
    setErrors([...errors, {}]);
    setBackendError('')
  };

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index); // Remove the selected row
    setRows(updatedRows);
    const updatedErrors = errors.filter((_, i) => i !== index);
    setErrors(updatedErrors);
    setBackendError('');
  };

  const handleSaveAndClose = async () => {
    const validationErrors = rows.map((row) => {
      let rowErrors = {};
      if (isValidEmail(row.email_id)) {
        rowErrors.email_id = "Invalid email address";
      }

      if (!row.shared_user_name.trim()) {
        rowErrors.shared_user_name = "Username is required";
      }

      return rowErrors;
    });

    const hasErrors = validationErrors.some((rowErrors) => Object.keys(rowErrors).length > 0);

    if (hasErrors) {
      setErrors(validationErrors);
      return; // Stop form submission if there are validation errors
    }
    try {
      const formData = new FormData();
      formData.append('quizId', id);

      const currentPage = window.location.pathname; // Get the current page or route
      // Send data to the backend
      await axios.post(
        apiUrl, // API endpoint passed as prop
        {
          quiz_id: id, // Pass quiz_id along with the data
          data: rows
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Send token in headers
            "Content-Type": "application/json",
            'Page-Name': currentPage,
          },
        }
      );
      onSave(); // Notify parent component about successful save (if needed)
      setRows([{ email_id: "", shared_user_name: "", participants_id:"", shared_group_id:""  }]); // Reset the form
    } catch (error) {
      // Set backendError to the error message from the backend
      if (error.response && error.response.data && typeof error.response.data.error === 'string') {
        setBackendError(getString(error.response.data.error) );
      } else {
        setBackendError("An error occurred while saving the data.");
      }
      if (error.response && error.response.status === 401) {
        navigate('/logout');
      }
    }
  };

  return (
    <Modal open={true} onClose={onSave} aria-labelledby="modal-title">
      <Box
        sx={{
          position: "absolute",
          top: "40%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "85%", 
          maxHeight: "90vh", 
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          overflowY: "auto", 
          overflowX: "auto", 
        }}
      >
        <Typography id="modal-title" variant="h5" gutterBottom>{getString('GQSD')}</Typography>

        <Grid container spacing={1}>
          {rows.map((row, index) => (
            <Grid container spacing={1} key={index} alignItems="center" size={{ xs: 'grow', md: 12 }}> 
              <Grid size={{ md: 3 }}>
                <TextField
                  label={getString('GLEM')}
                  margin="normal"
                  value={row.email_id}
                  name="email"
                  id="email"
                  fullWidth
                  onChange={(e) => handleInputChange(index, "email_id", e.target.value)}
                  error={!!errors[index]?.email_id}
                />
                {errors[index]?.email_id && (
                  <Typography color="error" variant="caption">
                    {errors[index].email_id}
                  </Typography>
                )} 
              </Grid>
              <Grid size={{ md: 3 }}>
                <TextField
                  label={getString('GLNM')}
                  margin="normal"
                  value={row.shared_user_name}
                  name="Name"
                  id="Name"
                  fullWidth
                  onChange={(e) => handleInputChange(index, "shared_user_name", e.target.value)}
                  error={!!errors[index]?.shared_user_name}
                />
                {errors[index]?.shared_user_name && (
                  <Typography color="error" variant="caption">
                    {errors[index].shared_user_name}
                  </Typography>
                )}
              </Grid>
              <Grid size={{md: 2.7}}>
                <TextField select label={getString('GLGP')} fullWidth  margin="normal" value={row.shared_group_id} name="shared_group_id" id="shared_group_id" onChange={(e) => handleInputChange(index, "shared_group_id", e.target.value)} >
                  {groups && groups.map((dd) => (
                    <MenuItem key={dd.code} value={dd.code}>
                      {dd.description}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid size={{md: 2.7 }}>
                <TextField select label={getString('GLPT')}  fullWidth margin="normal" value={row.participants_id} name="participants_id" id="participants_id" onChange={(e) => handleInputChange(index, "participants_id", e.target.value)} >
                  {participants && participants.map((dd) => (
                    <MenuItem key={dd.code} value={dd.code}>
                      {dd.description}
                    </MenuItem>
                  )
                )}
                </TextField>
              </Grid>
              <Grid size={{ md: .3 }}>
                <IconButton onClick={handleAddRow} color="primary">
                  <AddCircleOutlineIcon />
                </IconButton>
              </Grid>
              <Grid size={{ md: .3 }}>
                <IconButton onClick={() => handleDeleteRow(index)} disabled={rows.length === 1}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
            ))}
            {backendError && 
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="error">{backendError}</Alert>
              </Stack>
            }
            <Grid size={{ xs: 'grow', md: 12 }} offset="auto">
              <Box sx={{ '& button': { m: 1 } }}>                                  
                <Button
                  variant="contained"
                  onClick={handleSaveAndClose}
                  color="primary"
                  startIcon={<CloseIcon />}
                >
                  {getString('GLCS')}
                </Button>
              </Box>   
            </Grid>      
        </Grid>
      </Box>
  </Modal>
  );
};
export default ModalForm;
